import React, { ReactElement } from "react";

import StudioCTA from "./StudioCTA";

export default function DownloadSection(): ReactElement {
  return (
    <div className="bg-gradient-to-r from-violet-800 to-indigo-700">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-center text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block bg-gradient-to-r from-color-secondary to-color-primary bg-clip-text text-white">
            Ready to try Foxglove?
          </span>
        </h2>
        <StudioCTA page="download" />
      </div>
    </div>
  );
}
