import Link from "next/link";
import React, { ReactElement } from "react";

import { useAnalytics, AppEvent } from "../hooks/useAnalytics";

export const darkStyles =
  "text-white bg-gradient-to-r from-color-secondary to-color-primary hover:from-color-secondary-hov hover:to-color-primary-hov";
export const lightStyles = "text-indigo-700 bg-indigo-100 hover:bg-indigo-200";

export default function StudioCTA({ page }: { page: string }): ReactElement {
  const analytics = useAnalytics();

  return (
    <Link
      href="https://app.foxglove.dev/signup"
      className={`my-5 block text-center px-6 py-3 border border-transparent text-base sm:text-xl font-medium rounded-md shadow-sm ${lightStyles}`}
      onClick={() => analytics.trackEvent(AppEvent.GENERIC_GET_STARTED_CLICKED, { page })}
    >
      Get started for free
    </Link>
  );
}
