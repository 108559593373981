import React, { ReactElement } from "react";

import NewsletterForm from "./NewsletterForm";

type Props = {
  hasBackground: boolean;
};

export default function NewsletterSection({ hasBackground }: Props): ReactElement {
  return (
    <div className={`mb-2 px-4 sm:px-6 ${hasBackground ? "bg-color-primary" : ""}`}>
      <div className="px-6 py-6 md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div className="sm:text-center xl:w-0 xl:flex-1">
          <h2
            className={`text-3xl font-extrabold ${
              hasBackground ? "text-white" : "text-color-primary"
            } tracking-tight sm:text-3xl`}
          >
            Want product news and updates?
          </h2>
          <p
            className={`mt-3 text-lg ${
              hasBackground ? "text-gray-300" : "text-gray-500"
            } leading-6`}
          >
            Sign up for our newsletter to stay up to date with our latest features.
          </p>
          <div style={{ margin: "auto" }} className="mt-8 sm:w-full sm:max-w-sm xl:mt-0 xl:ml-8">
            <NewsletterForm btnStyle={hasBackground ? "light" : "dark"} />
          </div>
        </div>
      </div>
    </div>
  );
}
