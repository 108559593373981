import moment from "moment";
import Link from "next/link";
import React, { ReactElement } from "react";

import styles from "./BlogPostCard.module.css";
import { Page } from "../lib/cms";

export const postTypesToColors: Record<string, string> = {
  article: "blue",
  interview: "yellow",
  tutorial: "green",
};

export const contentTypesToColors: Record<string, string> = {
  ROS: "gray",
  visualization: "indigo",
  "data management": "pink",
  MCAP: "red",
};

export const postTypes = Object.keys(postTypesToColors);
export const contentTypes = Object.keys(contentTypesToColors);

export const blogTagsToColors: Record<string, string> = {
  ...postTypesToColors,
  ...contentTypesToColors,
};

export function authorImagePath(author: string): string {
  // Cloudflare Pages does not like spaces in file paths, so we normalize the image url.
  const normalizedAuthor = author
    .normalize("NFKD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase()
    .replace(/[^a-z]+/g, "");

  return `/images/blog/authors/${normalizedAuthor}.webp`;
}

export default function BlogPostCard({
  post,
  onTagClick,
  disableTags,
  entity = "blog",
  hideDetails = false,
}: {
  post: Page;
  onTagClick?: (tag: string) => () => void;
  disableTags?: boolean;
  entity?: string;
  hideDetails?: boolean;
}): ReactElement {
  const authors = (post.meta.author ?? "").split(", ");
  return (
    <div className="flex flex-col bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <Link href={`/${entity}/${post.slug ?? ""}`}>
          <img
            style={{ margin: "0px" }}
            className="aspect-video object-cover"
            src={post.meta.heroImage}
            alt={post.meta.title}
          />
        </Link>
      </div>
      <div className={styles.cardDescription}>
        <div className="flex flex-wrap">
          {(post.meta.tags ?? []).map((tag) => (
            <div
              key={tag}
              className={`${styles.tag ?? ""} ${
                styles[`tag-${blogTagsToColors[tag] ?? ""}`] ?? ""
              }`}
              style={disableTags === true ? { pointerEvents: "none" } : {}}
              onClick={onTagClick ? onTagClick(tag) : () => null}
            >
              {tag}
            </div>
          ))}
        </div>
        <div className="flex-1">
          <Link href={`/${entity}/${post.slug}`} className="block mt-2">
            <span className="text-xl font-semibold">{post.meta.title}</span>
          </Link>
          <p className="text-base text-gray-500">{post.meta.subtitle}</p>
        </div>
        {!hideDetails && (
          <div className="flex-col items-center">
            <div className="flex items-center flex-shrink-0">
              {authors.map((author, idx) => (
                <div key={author} className="flex items-center">
                  <img
                    alt={author}
                    src={authorImagePath(author)}
                    style={{
                      height: "30px",
                      borderRadius: "30px",
                      margin: "5px",
                      marginTop: 0,
                      marginLeft: idx !== 0 ? "10px" : 0,
                    }}
                  />
                  <span className="sr-only">{author}</span>
                  <span className="text-sm font-medium text-gray-900">{author}</span>
                </div>
              ))}
            </div>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime={moment(post.meta.date).format("YYYY-MM-DD")}>
                {moment(post.meta.date).format("MMMM D, YYYY")}
              </time>
              <span aria-hidden="true">&middot;</span>
              <span>{post.meta.readTime} read</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
